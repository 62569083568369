

  .navLinks {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .navLinks a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
  }

  
  .navLinks a:hover {
    color: #f39c12;
  }

  .logo {

    display: flex;
    flex-direction: row;
    margin-top: 4px;
    z-index: -2;
    align-items: center;
    justify-content: center;

  }
  
  .Nav_icon{
    margin-right: 20px;

    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;

  }

  .Nav_icon.rotated {
    transform-origin: 50%;
    transform: rotate(-180deg);
  }

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #29053A;
  padding: 10px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-style: solid;
  border-top: initial;
  border-left: initial;
  border-right: initial;
  border-bottom-width: 2px;
  border-color: #310645;
  height: 60px;
  justify-content: space-between;
}

.Search_icon {
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 -1px;
    width: 50px;
    height: 100%;
    background-color: #00c2ff;
    border-radius: 0px 30px 30px 0px;
}

.Close_icon{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 -1px;
  width: 50px;
  height: 100%;
  background-color: #ff284c;
  border-radius: 0px 30px 30px 0px;

}

 .s_icon {
  width: 16px;
  height: 16px;
}

.searchForm {
  display: flex;
  align-items: center;
  background-color: #f8e8ff;
  width: 500px;
  border-radius: 25px;
  height: 36px;

}

.searchInput {
  margin-left: 10px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 800;
  width: 100%;
  height: 36px;
  padding: 8px;
  background-color: #f8e8ff ;
  border: none;
  border-radius: 20px;
  outline: 0;
  
}

.searchButton {
  padding: 8px 16px;
  border: none;
  background-color: #0070f3;
  color: white;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #005bb5;
}

.searchResults {
  position: relative;
    height: auto;
    max-height: 600px;
    min-height: 120px;
    background: #29053a;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    overflow-x: hidden;
}


.searchResults::-webkit-scrollbar {
  width: 12px;
}

.searchResults::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #1f032d;
}

.searchResults::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00c2ff;
}
.Results_Container{

   display: flex;
    margin-top: var(--Top-Padding);
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    border-style: solid;
    border-top: 0;
    border-top: initial;
    border-left: 0;
    border-left: initial;
    border-right: 0;
    border-right: initial;
    border-bottom-width: 2px;
    border-color: #310645;
    height: 100Vh;
    
}
.Results_Padding{

  width: calc(50% - 295px);
  height: 100%;
}
.Results_Holder{
  margin: 10px;
}

.searchResultItem {
  width: 474px;
    background: #29053a;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    border-bottom: 3px solid #1f032d ;
}

.searchResultItem:hover {
  border-radius: 8px;
  background: #3b0257 ;
}

.searchResultItem span {
  color: #fff;
  padding-left: 12px;
  text-wrap: pretty;
  text-align: start;
}
.resultImage {
  width: 120px;
  height: 80px;
  margin-bottom: 12px;
  border-radius: 4px;
}
.closeContainer {
  margin-top: 8px;
  margin-left: 8px;
  position: fixed;
  padding-left: 380px;
  border: none;
}

.closeButton {
  position: fixed;
  padding: 0px;
  border: none;
  background: transparent;
  font-size: 37px;
  cursor: pointer;
}

.closeButton:hover {
  color: #00c2ff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.A_tag{
text-decoration: none;
}

@media (max-width: 370px) {
  .searchResultItem {
    width: 280px;
}
}

@media (min-width: 370px) and (max-width: 450px) {
  .searchResultItem {
    width: 300px;
}

}

@media (min-width: 450px) and (max-width: 550px) {
  .searchResultItem {
    width: 370px;
}
}


@media (max-width: 770px) {
  .Nav_icon{
    display: block;

  }
  .searchForm {
    width: 300px;
    margin-right: 7%;
    margin-left: 20px;
  }
  .searchInput{
    font-size: 16px;
  }

  .s_icon {
    width: 22px;
    height: 22px;
}
}


@media (min-width: 770px) {
  .Nav_icon{
    display: none;
    width: 0px;

  }
}
