/* Spinner.module.css */

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 10000000; /* Ensure it appears above other content */
    
  }
  .loader {
    opacity: 1;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #6f15ff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  /* .loader {
    opacity: 1;
    width: 300px;
    height: 300px;
    animation: spin 300ms linear infinite;
  } */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes scaling {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    50% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }
    100% {
      transform: scale(1.0);
        -webkit-transform: scale(1.0);
  }
}