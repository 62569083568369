/* Layout.module.css */

.page {
    opacity: 0.15;
    z-index: 10000;
  }
  
  .pageTransition {
    opacity: 0.15; /* This will fade out when a route change starts */
    z-index: 10000;
  }
  
  .pageVisible {
    opacity: 1; /* This will fade in when the route change is complete */
    z-index: 10000;
  }