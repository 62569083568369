/* components/SideNavbar.module.css */

.sideNavbar {
    position: fixed;
    top: 60px;
    left: 0;
    width: 0px;
    height: calc(100% - 60px);
    background-color: #29053A;
    overflow-y: auto; /* Make the sidebar scrollable */
    overflow-x: hidden;
    transition: width 0.3s;
    z-index: 999;
    border-right: 2px solid #310645;
  }


  .sideNavbar.clicked {
    width: 60px; 
    transition: width 0.3s;/* Example of a clicked style */
    /* Add any other styles you want for the clicked item */
  }

  
  /* Custom scrollbar styling */
  .sideNavbar::-webkit-scrollbar {
    width: 8px;
    display: none;
  }
  
  .sideNavbar:hover::-webkit-scrollbar {
    display: block;
  }
  
  .sideNavbar::-webkit-scrollbar-thumb {
   
    border-radius: 10px;
     background-color: #00c2ff;
  }
  
  .sideNavbar ul {
    list-style: none;
    padding: 0px;
    padding-top: 10px;
    margin: 0;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Changed to flex-start */
  }
  
  .categoryItem {
    height: 45px;
    padding: 10px 20px;
    font-weight: 800;
    color: #fff;
    white-space: nowrap;
    display: flex;
    align-items: center;
    transition: padding-left 0.3s;
    cursor: pointer;
    position: relative;
    user-select: none;
  }
  
  .categoryItem .icon {
    width: 25px;
    height: 25px;
    margin-right: 14px;
  }
  
  .sideNavbar:hover .categoryItem .categoryName {
    opacity: 1;
    transform: translateX(0);
  }
  
  .categoryItem:hover {
    padding-left: 40px;
    background-color: #4f0273;
    border-radius: 4px;
  }
  
 
  .categoryItem.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 80%;
    background-color: #00c2ff ;
    /* border-radius: 2px; */
  }


/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with opacity */
  z-index: 100; /* Ensure it's behind the sidebar but above other content */
}




  @media (min-width: 770px) {
    .sideNavbar {
      width: 60px !important; /* Default width on PC */
    }
  
    .sideNavbar:hover {
      width: 200px !important;
    }
    
    .categoryItem .categoryName {
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      transform: translateX(-20px);
    }
  }
  
  @media (max-width: 770px) {

    
    .sideNavbar {
      width: 0px; /* Default width on PC */
    }

    .sideNavbar::-webkit-scrollbar {
      display: block;
    }
  

    .categoryItem .categoryName {
      opacity: 1;
      transition: opacity 0.3s, transform 0.3s;
    }
  }

